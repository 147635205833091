.addedFieldButtonContainer{
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.addedFieldButton{
  width: 50%;
}
