#root > nav{
  background-color: #27466c !important;
}


div.navbar-nav > div.nav-item > a.nav-link{
  color: rgba(255,255,255,.9) !important;
}

div.navbar-nav > div.nav-item > a.disabled{
  color: rgba(255,255,255,.5) !important;
}
