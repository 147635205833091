.searchButton{
  margin: 10px;
}

.tableControls{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tableControls{
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.pageControls{
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 10px;
}

.totalResultsDisplay{
  display: flex;
  justify-self: center;
  margin-bottom: 0px;
  margin-left: 4px;
}

/* Style Improvements Ene-2023 */
/* Region input search */
.form-group-search input { background-color: #e2eaf3; }
.form-control:focus {box-shadow: none;}
.form-group-search button {margin-top: 8px;}

.files-attached-list svg {cursor: pointer;}
.svg-inline--fa.fa-times {
  color: #dc3545;
}